import './Design.css'
import deco1 from './res/cyberdeco.png'

function Design() {

    return (
        <div className='mainDesign'>
            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco1" />
            </div>

            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco2" />
            </div>

            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco3" />
            </div>

            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco4" />
            </div>

            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco5" />
            </div>

            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco6" />
            </div>

            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco7" />
            </div>

            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco8" />
            </div>

            <div className='svg-deco1'>
                <img src={deco1} alt="Banner" className="deco9" />
            </div>
        </div>
    )

}
export default Design;