import React from 'react';
import './Footer.css';
import iconFB from './res/icon-fb.png'
import iconIG from './res/icon-insta.png'
import iconLI from './res/icon-linkedin.png'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="left-column">
                <div className="copyright">
                    <a href='https://cyberproteg.com'>CYBERPROTEG</a>  Todos los derechos Reservados. ©
                </div>
                <div className="copyright">
                    info@cyberproteg.com
                </div>
                <div className="github-link">
                    <a href="https://github.com/DsT-Sol" target="_blank" rel="noopener noreferrer">
                        by @github.com/DsT-Sol
                    </a>
                </div>
            </div>
            <div className="right-column">

                <div className="footer-link">
                    <a href="https://www.facebook.com/profile.php?id=61559111224297" target="_blank" rel="noopener noreferrer">
                        <img src={iconFB} alt="iconFB" className="footer-image" />
                    </a>
                </div>

                <div className="footer-link">
                    <a href="https://www.instagram.com/cyberproteg/" target="_blank" rel="noopener noreferrer">
                        <img src={iconIG} alt="iconIG" className="footer-image" />
                    </a>
                </div>

                <div className="footer-link">
                    <a href="https://www.linkedin.com/company/cyber-proteg/" target="_blank" rel="noopener noreferrer">
                        <img src={iconLI} alt="iconLI" className="footer-image" />
                    </a>
                </div>
            </div>
        </footer>
    );
}
