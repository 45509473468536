import './Section5.css';
import React, { useState} from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

function Section5() {

  

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const recaptchaSiteKey = '6LfZfPQpAAAAAAimzZNIKSs3zRhgadmyasaQ3Gs6';

  const submit = () => {
    if (name && email && message) {
      if (!isValidEmail(email)) {
        setError('Por favor ingrese un email válido.');
        return;
      }

      if (!recaptchaToken) {
        setError('Por favor verifica que no eres un robot.');
        return;
      }

      const serviceId = 'service_id';
      const templateId = 'template_id';
      const templateParams = {
        name,
        email,
        message,
      };

      emailjs.send(serviceId, templateId, templateParams)
        .then(response => {
          console.log(response);
          setName('');
          setEmail('');
          setMessage('');
          setEmailSent(true);
          setError('');
          setRecaptchaToken(''); // Reset reCAPTCHA
        })
        .catch(error => {
          console.log(error);
          setError('Failed to send email. Please try again later.');
        });
    } else {
      setError('Por favor rellenar todos los campos.');
    }
  };

  const isValidEmail = email => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  emailjs.init('fBNbjASOH5wk-F08R');

  return (
    <div className="section-5">
      <h1>Contáctanos</h1>
      <p>Envíanos un email</p>
      <div id="contact-form">
        <input
          type="text"
          placeholder="Nombre*"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email*"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <textarea
          placeholder="Mensaje*"
          value={message}
          onChange={e => setMessage(e.target.value)}
          maxLength={150}
        ></textarea>
        <div className="char-count">{message.length}/150</div>
        <ReCAPTCHA className='recaptcha-container'
          sitekey={recaptchaSiteKey}
          onChange={token => setRecaptchaToken(token)}
        />
        <button onClick={submit}>Enviar</button>
        {emailSent && <span className="visible">Gracias por contactarnos, nos comunicaremos lo más pronto posible.</span>}
        {error && <span className="error">{error}</span>}
      </div>
    </div>
  );
}

export default Section5;
