// WhatsAppBtn.js

import React from 'react';
import './WhatsAppBtn.css';
import iconWA from './res/icon-wa.png';

const WhatsAppBtn = (props) => {
  const { phoneNumber, message } = props;
  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${message}`}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={iconWA} alt="WhatsApp Icon" className="whatsapp-icon" />
      <p className="whatsapp-text">Contáctanos ahora!</p>
    </a>
  );
};

export default WhatsAppBtn;

