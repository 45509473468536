import React, { useState } from 'react';
import banner from './res/cyber-banner.png';
import './App.css'; // Import the CSS file
import Section1 from './Sections/Section1';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';
import Section4 from './Sections/Section4';
import Section5 from './Sections/Section5';
import Footer from './Footer';
import WhatsAppBtn from './WhatsAppBtn';
import Design from './Design';


function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeBgrMenu = () => {
    setMenuOpen(false);
  };


  const phoneNumber = '573132046487';
  const message = encodeURIComponent('Hola, me gustaría obtener más información sobre sus servicios');


  return (
    <div className="App">
      

      <header className="App-header">
        <div className="top-bar">
          <img src={banner} alt="Banner" className="logo" />
          <nav className={`menu ${menuOpen ? 'open' : ''}`}>
            <ul>
            <li><a href="https://www.cyberproteg.com" onClick={closeBgrMenu}>Inicio</a></li>
              <li><a href="https://www.instagram.com/cyberproteg/" onClick={closeBgrMenu}>Blog</a></li>
              <li><a href="#anchorSection4" onClick={closeBgrMenu}>Nosotros</a></li>
              <li><a href="#anchorSection5" onClick={closeBgrMenu}>Contacto</a></li>
            </ul>
          </nav>
          <div className={`bgr-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            &#9776;
          </div>
        </div>
      </header>

      <Design />

      <Section1 />

      <Section2 phoneNumber={phoneNumber} />

      <Section3 />

      <div id='anchorSection4'>4</div>
      <Section4 phoneNumber={phoneNumber} />

      <div id='anchorSection5'>5</div>
      <Section5 />
      

      <WhatsAppBtn phoneNumber={phoneNumber} message={message} />

      <Footer />

    </div>
  );
}

export default App;
