import './Section4.css'
import dataprotec from '../res/icon-dataprotec.png'
import intrusion from '../res/icon-intrusion.png'
import backup from '../res/icon-backup.png'
import teach from '../res/icon-teach.png'
function Section4(props) {
  const { phoneNumber } = props;
  const handleClick = () => {
    const message = encodeURIComponent("Me interesa obtener una consulta gratis");
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  }
  return (
    <div className="section-4">
      <h1>NUESTROS SERVICIOS</h1>
      <p>Evaluamos cada caso por separado y ofrecemos exactamente lo que tu negocio necesita, sin servicios innecesarios o pagos ocultos.</p>
      <div className="packages">
        <div className="package">
          <img src={dataprotec} alt="icon-dataprotec" className="icon-img" />
          <h2>Protección de Datos</h2>
          <hr />
          <p>Ofrecemos soluciones integrales para proteger tus datos contra amenazas internas y externas</p>

        </div>
        <div className="package">
          <img src={intrusion} alt="icon-intrusion" className="icon-img" />
          <h2>Prevención de Intrusiones</h2>
          <hr />
          <p>Utilizamos tecnologías avanzadas para detectar y prevenir intrusiones en tu red</p>

        </div>
        <div className="package">
          <img src={backup} alt="icon-backup" className="icon-img" />
          <h2>Recuperación de Desastres</h2>
          <hr />
          <p>En caso de un incidente de seguridad, tenemos planes de recuperación de datos asegurando la continuidad del negocio</p>

        </div>
        <div className="package">
          <img src={teach} alt="icon-teach" className="icon-img" />
          <h2>Formación en Seguridad</h2>
          <hr />
          <p>Ofrecemos formación a tu personal para mejorar su conciencia de seguridad y reducir el riesgo de incidentes</p>

        </div>

      </div>
      <button className="get-started" onClick={handleClick}>Consulta Gratuita</button>
    </div>
  )

}
export default Section4;