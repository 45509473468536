import './Section3.css'
import whyus from '../res/img-whyus.png'

function Section3() {

  return (
    <div className="section-3">

      <div className='img-containter3'>
        <img src={whyus} alt="Whyus" className="watermark" />
      </div>
      <div className='text-container3'><h1>¿Por qué CYBERPROTEG?</h1>
        <p> Ofrecemos soluciones personalizadas que se adaptan a las necesidades específicas de tu negocio. </p>
        <p>Nos mantenemos al día con las últimas tendencias y tecnologías en ciberseguridad para ofrecer mejores soluciones a nuestros clientes.</p>
      </div>
    </div>
  )

}
export default Section3;