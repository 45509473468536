import './Section1.css'
import img1 from '../res/img-section1.png'

function Section1() {

  return (
    <div className="section-1">

      <div className='text-container1'><h1>Protegemos tu negocio contra amenazas cibernéticas</h1>
      </div>

      <div className='img-containter1'>
        <img src={img1} alt="img1" className="img1" />
      </div>

    </div>
  )

}
export default Section1;