import './Section2.css'

import assets from '../res/icon-assets.png'
import diagnostic from '../res/icon-diagnostic.png'
import safety from '../res/icon-safety.png'
import update from '../res/icon-update.png'
import ethical from '../res/icon-etichal.png'


function Section2(props) {
  const { phoneNumber } = props;
  const handleClick = () => {
    const message = encodeURIComponent("Me interesa obtener una consulta gratis");
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  }

  return (
    <div className="section-2">
      <div className="left-part">
        <div className="card">
          <img src={assets} alt="icon-assets" className="icon-img" />
          <h3>Identificación</h3>
          <p>Verificamos que activos dentro de tu organización necesitan protección</p>
        </div>
        <div className="card">
          <img src={diagnostic} alt="icon-diagnostic" className="icon-img" />
          <h3>Evaluación</h3>
          <p>Evaluamos potenciales riesgos y vulnerabilidades</p>
        </div>
        <div className="card">
          <img src={safety} alt="icon-safety" className="icon-img" />
          <h3>Seguridad</h3>
          <p>Implementamos medidas de seguridad</p>
        </div>
        <div className="card">
          <img src={ethical} alt="icon-ethical" className="icon-img" />
          <h3>Hacking ético</h3>
          <p>Entrega de informe que incluye un resumen ejecutivo, pentesting, evidencias, análisis de vulnerabilidades, recomendaciones y plan de acción</p>
        </div>
        <div className="card">
          <img src={update} alt="icon-update" className="icon-img" />
          <h3>Actualización</h3>
          <p>Nuestro compromiso estar a vanguardia frente a las situaciones presentadas</p>
        </div>
      </div>
      <div className="right-part">
        <h1>¿Qué es CYBERPROTEG?</h1>
        <p>Ayudamos a tu negocio a estar constantemente seguro contra las amenazas cibernéticas y aseguramos que puedas operar con total confianza en el mundo digital.</p>
        <p>Evaluamos las necesidades de tu negocio considerando factores como el tamaño de tu organización, el tipo de datos que manejas y tu industria.</p>
        <button className="learn-more-button" onClick={handleClick}
        >Consulta Gratuita
        </button>
      </div>
    </div>
  )

}
export default Section2;